import React from "react";
import Container from "@material-ui/core/Container";
import {data} from '../assert/data/sdkList'

export default function PrivacyPolicy(props) {
	return (
		<Container maxWidth="md">
			<div
				variant={'body2'}
				style={{
					lineHeight: 1.6,
					textAlign: 'justify',
					whiteSpace: 'pre-line',
					fontWeight: '500',
					fontFamily: 'Serif'
				}}>
				{'Youla平台（以下简称“本平台”或“我们”）由重庆月下童信息科技有限公司提供服务，我们非常重视用户的隐私。您在使用我们的服务时，我们会收集和使用您的相关个人信息及用户信息（以下统称为“信息”）。我们希望通过《Youla隐私政策》（以下简称《隐私政策》或“本政策”）向您说明，您在使用我们的服务时，我们如何收集、使用、储存、共享、转移、披露这些信息，以及您可以通过本文所列途径及方式查阅、复制、更正、补充、删除你的个人信息，也可以撤回同意协议、注销账号、投诉举报的隐私功能。\n' +
				'\t\t\t\t\n'}
				<div
					variant={'body2'}
					style={{
						lineHeight: 1.6,
						textAlign: 'justify',
						whiteSpace: 'pre-line',
						fontWeight: '800',
						fontFamily: 'Serif',
						color: 'yellow'
					}}>
					{'【特别提醒】在使用Youla服务前，请您务必仔细阅读并透彻理解本政策，在确认充分理解并同意后再开始使用。如果您不同意本政策的内容，将可能导致我们的产品和服务无法正常运行，或者无法达到我们拟达到的服务效果，您应立即停止访问/使用我们的产品与/或服务。您使用或继续使用Youla服务，都表示您同意我们按照本政策（包括更新版本）收集、使用、储存、分享、转让和披露您的个人信息。'}
				</div>
				{
				'\t\t\t\t\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t一、我们如何收集和使用个人信息\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t我们收集您的个人信息主要是为了您和其他用户能够更容易和更满意地使用Youla服务。Youla的目标是向所有的互联网用户提供安全、有趣及有教益的上网经历。而这些信息有助于我们实现这一目标。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（一）我们将通过以下途径收集和获得您的个人信息：\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t1、您提供的信息。 例如：\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（1）您在注册Youla服务的账号或使用Youla服务时，向我们提供的信息；\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（2）您通过Youla服务向其他方提供的共享信息，以及您使用Youla服务时所储存的信息。\n' +
				'\t\t\t\t\n' }
				<div
					variant={'body2'}
					style={{
						lineHeight: 1.6,
						textAlign: 'justify',
						whiteSpace: 'pre-line',
						fontWeight: '800',
						fontFamily: 'Serif',
						color: 'yellow'
					}}>
					请注意，如您在Youla服务中其他用户可见的公开区域内上传或发布的信息中、您对其他人上传或发布的信息作出的回应中公开您的信息，该等信息可能会被他人收集并加以使用。当您发现他人不正当地收集或使用您的信息时，可通过本政策“如何联系我们”章节所列的反馈渠道联系我们。
				</div>{
				'\t\t\t\t\n' +
				'\t\t\t\t2、第三方合法共享的您的信息。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t3、我们在向您提供服务的过程中合法获取的您的使用信息。您在使用Youla服务时，我们收集、汇总、记录的信息，日志信息、位置信息、设备信息。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（二）我们会因以下目的，收集和使用您以下的个人信息：\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t1、为帮助您完成注册及登录\n' +
				'\t\t\t\t\n' +
				'\t\t\t\tYoula支持手机号或第三方（如有）注册/登录。当您选择手机号注册/登录时，您需要输入手机号和短信验证码登录，我们会获取您的手机号信息；又或通过用户名和密码登录。若您以第三方方式关联登录，我们会向关联的第三方请求您的个人信息，如：当您选择微信注册/登录时，我们会访问您的微信唯一标识、微信头像、昵称、性别与地区信息；\n' +
				'\t\t\t\t\n'}
				<div
					variant={'body2'}
					style={{
						lineHeight: 1.6,
						textAlign: 'justify',
						whiteSpace: 'pre-line',
						fontWeight: '800',
						fontFamily: 'Serif',
						color: 'yellow'
					}}>
					手机号码属于个人敏感信息，收集这些信息是为了满足《移动互联网应用程序信息服务管理规定》的网络实名制要求和帮助您获取Youla账号，并以此保护您账号的安全。若您不提供，您将无法正常使用我们的服务。
				</div>{
				'\t\t\t\t\n' +
				'\t\t\t\t您提供的上述信息，将在您使用本服务期间持续授权我们使用。我们会一直保存您的信息直至您注销账号。在您注销账号时，我们将停止使用上述信息，对其进行删除或匿名化处理。法律、行政法规另有规定的从其规定。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t2、向您提供服务或商品\n' +
				'\t\t\t\t\n' }
				<div
					variant={'body2'}
					style={{
						lineHeight: 1.6,
						textAlign: 'justify',
						whiteSpace: 'pre-line',
						fontWeight: '800',
						fontFamily: 'Serif',
						color: 'yellow'
					}}>
					{'\t\t\t\t（1）面部特征信息。您在上传头像/个人照片时，我们需要鉴定您上传的头像/个人照片是否真实、清晰以及是否涉及我国法律法规明令禁止的违法内容，为此我们将收集您的个人生物识别信息中的面部特征信息。个人生物识别信息中的面部特征信息属于敏感信息，若您不提供这类信息，您将无法被本平台推荐给其他用户。\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t（2）身份证照片。在使用Youla进行身份认证服务时，您需要提供以下信息供我们收集：身份证原件照片或扫描件。 身份证信息属于个人敏感信息，我们收集这些信息是为了满足国家法律法规的网络实名制要求，保证Youla产品真实、安全、严肃的社交氛围。如您已提供前述信息，未经您明示授权，本平台不会用作其它目的；您可以拒绝提供上述信息，这不影响您使用Youla的基本服务，但您将无法使用Youla的认证服务。\n'+
					'\t\t\t\t\n' +
					'\t\t\t\t（3）微信号码。在使用Youla进行服务时，若您想有朋友可直接添加您微信，在微信平台进行你们的沟通交流时，您需要提供您的微信号供我们收集。\n'
					}
				</div>
				{
				'\t\t\t\t\n' +
				'\t\t\t\t（4）出生日期。我们会基于您提供的出生日期判断您的年龄，如果您低于18周岁，您将无法正常继续使用服务。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（5）性别。我们会基于您提供的性别信息为您提供嘉宾匹配服务，如果您不向我们提供此类信息，您将无法注册成为我们的用户并继续使用服务。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（6）身高、学校、收入、职业、个人介绍、兴趣爱好等。您使用Youla交友服务时，我们会收集一些您额外的个人信息，额外的个人信息包括身高、收入、职业、个人介绍、兴趣爱好等。因Youla的交友模式，您提供的额外个人信息会被其他用户查阅。若您选择不提供上述额外的个人信息，不影响您使用Youla的基础服务；\n' +
				'\t\t\t\t\n' }
				<div
					variant={'body2'}
					style={{
						lineHeight: 1.6,
						textAlign: 'justify',
						whiteSpace: 'pre-line',
						fontWeight: '800',
						fontFamily: 'Serif',
						color: 'yellow'
					}}>
					（7）位置信息。您在使用Youla服务时，为了为您可以在发布小事时附带位置信息或平台进一步优化为您提供附近用户匹配，方便附近用户判断和联系您，我们会收集您的设备所在位置信息。位置信息属于个人敏感信息，您可以拒绝提供位置信息，但不影响您正常使用本平台的其他功能，您也可以随时取消您的位置信息授权；
				</div>
				{
				'\t\t\t\t\n' +
				'\t\t\t\t（8）相册权限/SD卡读取与储存权限。当您发布小事上传图片、或个人资料上传个人照片进行身份认证，以及在与其他用户聊天过程中上传手机相册图片时，我们会申请您的相册权限，若您不同意，将无法享受上传手机相册图片的服务；为了方便用户上传图片，保存图片到手机，我们会申请修改/删除SD卡中的内容、读取SD卡上的内容权限，在聊天时使用的语音功能，我们需获得您的SD读写权限，将语音文件写入并储存在SD卡中；\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（9）摄像头权限。当您同意开启此项权限后，我们会使用您设备上的相机拍摄功能。此项功能将用于实现照片拍摄、视频录制，并用于发布小事、个人资料编辑（头像/封面）、用户聊天、举报/反馈场景。若您拒绝该项授权，不影响您使用Youla服务。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（10）麦克风权限。当您想上传语音信息时，我们会申请您的麦克风录音权限，若您不同意，将无法上传语音信息，但不影响您使用Youla的服务；\n' +
				'\t\t\t\t\n' +
				// '\t\t\t\t（9）个性化推送的说明\n' +
				// '\t\t\t\t\n' +
				// '\t\t\t\t个性化推送功能是为了向您提供更契合您需求的页面内容、我们可能会收集并使用您的下列信息：\n' +
				// '\t\t\t\t\n' +
				// '\t\t\t\ta、您主动向我们提供的信息：反馈、发布、评论；\n' +
				// '\t\t\t\t\n' +
				// '\t\t\t\tb、您的大致位置信息：我们可能依赖您的IP地址、Wi-Fi接入点、移动信号基站位置来判断您所在的大致位置，并为您提供基于该等位置的同城服务。\n' +
				// '\t\t\t\t\n' +
				// '\t\t\t\t我们会根据上述所收集的信息，进行综合统计分析，从而为您提供个性化内容推荐、展示或推送您可能感兴趣的信息或者推送更合适您的特定功能或服务。此外，如您不希望使用推送功能，您也可以随时通过手机应用列表找到Youla应用，控制开关来关闭推送通知功能。\n' +
				'\t\t\t\t\n' }
				<div
					variant={'body2'}
					style={{
						lineHeight: 1.6,
						textAlign: 'justify',
						whiteSpace: 'pre-line',
						fontWeight: '800',
						fontFamily: 'Serif',
						color: 'yellow'
					}}>
					{'\t\t\t\t（11）充值订单信息。我们会收集您在使用Youla服务过程中产生的充值订单信息便于对账号上购买的增值服务进行管理。充值订单信息属于个人敏感信息，收集上述信息是为了实现Youla消费功能，否则将无法完成交易；'}
					{'\n\n\t\t\t\t您提供的面部特征信息、身份证照片信息、位置信息与充值订单，将在您使用本服务期间持续授权我们使用。我们会一直保存您的信息直至您注销账号。在您注销账号时，我们将停止使用上述信息，对其进行删除或匿名化处理。法律、行政法规另有规定的从其规定。\n'}
				</div>
				{
				'\t\t\t\t\n' +
				'\t\t\t\t（12）读取手机状态和身份权限。为了识别用户身份，保障账号信息安全和用户聊天交流，我们会申请发现已知账户、读取手机状态和身份权限，保存您的账号信息、账号、口令、昵称、头像；\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（13）网络访问日志信息。为了满足相关法律法规要求和网络安全保障需要，我们会保存您的网络访问日志信息：IP地址、用户登录时间、用户退出时间；\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（14）通信/通话记录和内容。当您联系我们的客服或使用其他用户响应功能时（个人信息保护投诉或建议、未成年人相关投诉或建议或其他客户投诉和需求），我们会保存您的联系方式（您与我们联系时使用的或您向我们主动提供的其他联系方式）、您与我们的通信/通话记录和内容、与您需求相关联的其他必要信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果；\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（15）剪切板信息。当您使用Youla服务时，我们会使用您设备上的剪切板功能（如有），识别剪切板的内容，以便您进行身份认证、复制聊天信息。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（16）获取应用列表。基于安全风控的目的，我们需要获取您的应用列表；\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t为了解您的产品适配性、识别账号异常状态，我们会收集关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t日志信息：当您使用我们的服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。包括您的登录账号、手机号、IP地址、操作系统、设备唯一标识、客户端版本\n' +
				'\t\t\t\t\n'}
				<div
					variant={'body2'}
					style={{
						lineHeight: 1.6,
						textAlign: 'justify',
						whiteSpace: 'pre-line',
						fontWeight: '800',
						fontFamily: 'Serif',
						color: 'yellow'
					}}>
					{'\t\t\t\t设备信息：为了保障移动互联网应用业务安全风控，应对反作弊、反欺诈、违法不良信息管控等安全风险，我们可能会保存您的设备信息，同时根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（包括设备唯一标识符、Android ID、操作系统、客户端版本）。\n' +
					'\t\t\t\t\n'}
				</div>
				{
				'\t\t\t\t请注意，单独的设备信息、日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t3、推送消息给您\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t向您发出通知。我们可能在必需时（包括当我们由于系统维护而暂停某一单项服务、变更、终止提供某一单项服务时）向您发出与服务有关的通知。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t4、为您提供安全保障\n' +
				'\t\t\t\t\n' }
				<div
					variant={'body2'}
					style={{
						lineHeight: 1.6,
						textAlign: 'justify',
						whiteSpace: 'pre-line',
						fontWeight: '800',
						fontFamily: 'Serif',
						color: 'yellow'
					}}>
					{'\t\t\t\t为提高您使用我们及合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或Youla服务相关协议规则的情况，我们可能会收集、使用或整合您的账户信息、交易信息、设备信息、日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。\n'}
					{'\t\t\t\t\n' +
					'\t\t\t\t5、推广与调研\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t当您选择参加我们及我们的关联方或第三方举办的有关调查问卷、抽奖中奖、线上或线下推广营销等运营活动时，可能需要您在相关表单中填写个人信息：姓名、通信地址、联系方式。如您拒绝提供个人信息，将会影响您参加前述运营活动，但不会影响您使用本平台其它功能。只有经过您的同意，我们及第三方才会收集和处理相关信息，以保障为您提供相应的产品服务。\n'}
				</div>
				{
				'\t\t\t\t\n' +
				'\t\t\t\t6、其他用途\n' +
				'\t\t\t\t\n' }
				<div
					variant={'body2'}
					style={{
						lineHeight: 1.6,
						textAlign: 'justify',
						whiteSpace: 'pre-line',
						fontWeight: '800',
						fontFamily: 'Serif',
						color: 'yellow'
					}}>
					{'\t\t\t\t您了解并同意，在收集您的个人信息后，我们可能通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别您的身份，在此情况下我们有权使用已经去标识化的信息，对用户数据库进行分析并予以商业化的利用，而无需另行获得您的同意。\n'}
				</div>
				{
				'\t\t\t\t\n' +
				'\t\t\t\t请您注意，如果我们要将您的个人信息用于本政策中未载明的其他用途或额外收集未提及的其他个人信息，我们会通过页面提示、交互流程、网站公告或其他方式另行征得您的同意。一旦您同意，该等额外用途将视为本政策的一部分，该等额外信息也将适用本政策。\n' }
				<div
					variant={'body2'}
					style={{
						lineHeight: 1.6,
						textAlign: 'justify',
						whiteSpace: 'pre-line',
						fontWeight: '800',
						fontFamily: 'Serif',
						color: 'yellow'
					}}>
					{'\t\t\t\t\n' +
					'\t\t\t\t7、根据相关法律法规规定，以下情形中处理您的信息无需征得您的授权同意：\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t（1）为订立、履行您作为一方当事人的合同所必需；\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t（2）为履行法定职责或者法定义务所必需；\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t（3）为应对突发公共卫生事件，或者紧急情况下为保护您或他人的生命健康和财产安全所必需；\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t（4）为公共利益实施新闻报道、舆论监督等行为，在合理范围内处理您的个人信息；\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t（5）在合理范围内处理您自行公开或者其他已经合法公开的个人信息；\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t（6）法律、行政法规规定的其他情形。\n'+
					'\t\t\t\t\n' +
						'\t\t\t\t8、您理解并同意，部分单项服务可能需要您在您的设备中开启特定的访问权限（包括您的位置信息、相机和存储），以实现这些权限所涉及信息的收集和使用。当您需要关闭该功能时，大多数移动设备都会支持您的这项需求，具体方法请参考或联系您移动设备的服务商或生产商。请您注意，您开启任一权限即代表您授权我们可以收集和使用相关信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。\n'}
				</div>
				{
				// '\t\t\t\t\n' +
				// '\t\t\t\t9、Cookie等同类技术的使用\n' +
				// '\t\t\t\t\n' +
				// '\t\t\t\t我们或我们的第三方合作伙伴可能通过COOKIES或同类技术获取和使用您的信息，并将该等信息储存为日志信息。\n' +
				// '\t\t\t\t\n' +
				// '\t\t\t\t通过使用COOKIES，我们向用户提供简单易行并富个性化的网络体验。一个COOKIES是少量的数据，它们从一个网络服务器送至您的浏览器并存在计算机硬盘上。我们使用COOKIES是为了让其用户可以受益。比如，为使得Youla平台的登录过程更快捷，您可以选择把用户名存在一个COOKIES中。这样下次当您要登录Youla的服务时能更加方便快捷。COOKIES能帮助我们确定您连接的页面和内容，您在Youla特定服务上花费的时间和您所选择的Youla服务。\n' +
				// '\t\t\t\t\n' +
				// '\t\t\t\tCOOKIES使得我们能更好、更快地为您服务，并且使您在Youla服务上的经历更富个性化。然而，您应该能够控制COOKIES是否以及怎样被您的浏览器接受。请查阅您的浏览器附带的文件以获得更多这方面的信息。\n' +
				// '\t\t\t\t\n' +
				// '\t\t\t\t我们和第三方合作伙伴可能通过COOKIES或同类技术收集和使用您的信息，并将该等信息储存。\n' +
				// '\t\t\t\t\n' +
				// '\t\t\t\t我们使用自己的COOKIES或同类技术，可能用于以下用途：\n' +
				// '\t\t\t\t\n' +
				// '\t\t\t\t（1）记住您的身份。例如：COOKIES或同类技术有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供有关您的喜好或其他信息；\n' +
				// '\t\t\t\t\n' +
				// '\t\t\t\t（2）分析您使用我们服务的情况。我们可利用COOKIES或同类技术来了解您使用Youla服务进行什么活动、或哪些服务最受欢迎；\n' +
				// '\t\t\t\t\n' +
				// '\t\t\t\t我们为上述目的使用COOKIES或同类技术的同时，可能将通过COOKIES或同类技术收集的非个人身份信息汇总提供给合作伙伴，用于分析您和其他用户如何使用Youla服务并用于服务。\n' +
				// '\t\t\t\t\n' +
				// '\t\t\t\tYoula服务上可能会有合作方放置的COOKIES或同类技术。这些COOKIES和或同类技术可能会收集与您相关的非个人身份信息，以用于分析用户如何使用该等服务。这些第三方COOKIES或同类技术收集和使用该等信息不受本政策约束，而是受到其自身的信息保护声明约束，我们不对第三方的COOKIES或同类技术承担责任。\n' +
				// '\t\t\t\t\n' +
				// '\t\t\t\t您可以通过浏览器或用户选择机制拒绝或管理COOKIES或同类技术。但请您注意，如果您停用COOKIES或同类技术，我们有可能无法为您提供最佳的服务体验，某些服务也可能无法正常使用。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t二、我们共享、转移和披露您的个人信息\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（一）共享\n' +
				'\t\t\t\t\n'}
				<div
					variant={'body2'}
					style={{
						lineHeight: 1.6,
						textAlign: 'justify',
						whiteSpace: 'pre-line',
						fontWeight: '800',
						fontFamily: 'Serif',
						color: 'yellow'
					}}>
					{'\t\t\t\t为了向您提供Youla平台的相关服务，保障Youla平台的稳定运行以及其他合理目的，我们会在以下特定情形下向Youla平台之外的第三方共享您的个人信息。\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t1、向您提供我们的服务。我们会向合作伙伴及其他第三方分享您的信息，以实现我们产品与服务的功能，让您正常使用您需要的服务，例如：提供支付服务的支付机构、提供数据服务（包括数据统计、数据分析、数据储存）的合作伙伴、和其他服务提供商；\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t第三方信息共享具体情况如下：\n' +
					'\t\t\t\t\n'}
					<div style={{}}>
						{data.map((sdks,index)=>{
							return <div style={{marginBottom: 12, lineHeight: 2}}>
								{`${sdks?.key}、${sdks?.title}`}{'\n'}
								{`SDK厂商：${sdks?.changShang}`}{'\n'}
								{`SDK用途：${sdks?.muDi}`}{'\n'}
								{`SDK收集的个人信息类型：${sdks?.info}`}{'\n'}
								{`SDK隐私政策链接：${sdks?.privacy}`}{'\n'}
							</div>
						})}
						{/*<Table*/}
						{/*	pagination={false}*/}
						{/*	columns={columns}*/}
						{/*	dataSource={data}*/}
						{/*	bordered={false}*/}
						{/*	size='small'*/}
						{/*	showHeader={false}*/}
						{/*	rowClassName={record => {*/}
						{/*		return 'ant-table-tbody';*/}
						{/*	}}*/}
						{/*/>*/}
					</div>
					{
						'\t\t\t\t\n' +
					'\t\t\t\t以上第三方服务由相关的第三方负责运营。您使用该等第三方服务（包括您向该等第三方提供的任何信息），须受第三方自己的服务条款及个人信息保护相关规则（而非本政策）约束，您需要仔细阅读其条款。本政策仅适用于我们所收集的个人信息，并不适用于任何第三方提供的服务或第三方的信息使用规则。如您发现这些第三方服务存在风险时，建议您终止相关操作以保护您的合法权益并及时与我们取得联系。\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t2、与关联方的必要分享。为便于我们基于统一的账号体系向您提供一致化服务以及便于您进行统一管理、保障系统和账号安全等，您的个人信息会在我们和我们的关联方之间进行必要共享；\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t3、实现本政策第一条“我们如何收集和使用个人信息”部分所述目的；\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t4、履行我们在本政策或我们与您达成的其他协议中的义务和行使我们的权利；\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t5、向委托我们进行推广的合作伙伴等第三方共享，目的是为了使该等委托方了解推广的覆盖面和有效性。比如我们可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后购买了委托方的商品，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客；\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t6、在法律法规允许的范围内，为了遵守法律、维护我们及我们的关联方或合作伙伴、您或其他Youla用户或社会公众利益、财产或安全免遭损害，比如为防止欺诈等违法活动和减少信用风险，我们可能与其他公司和组织交换信息。不过，这并不包括违反本政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的信息；\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t7、应您合法需求或经您的授权同意；\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t8、应您的监护人合法要求而提供您的信息；\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t9、根据与您签署的单项服务协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t10、为提高我们的安全管理和技术科研能力，促进服务安全和技术水平，以便为用户提供更优质的服务，我们会在合理必要的范围内，确保目的正当和数据安全的前提下，与研究机构、科研院所、高校等机构共享经去标识化或匿名化处理后的数据。未经您的明确授权，我们不会向上述机构共享可用于识别您个人身份的信息；\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t11、基于符合法律法规的社会公共利益而提供。\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息。对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理信息。\n'}
				</div>
				{
				'\t\t\t\t\n' +
				'\t\t\t\t（二）转移\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t未经您的授权，我们不会向第三方转移您的个人信息。因我们可能发生合并、收购、资产转让、分立、解散、被宣告破产或类似情况，您的个人信息有可能需要转移。我们将会将相关情况告知您，向您告知接收方的名称和联系方式，并会要求新的接收方按照法律法规及不低于本政策的的要求继续保护您的个人信息。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（三）披露\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的个人信息：\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t1、根据您的需求，在您明确同意的披露方式下披露您所指定的信息；\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您信息的情况下，我们可能会依据所要求的信息类型和披露方式披露您的信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。\n' +
				'\t\t\t\t\n' }
				<div
					variant={'body2'}
					style={{
						lineHeight: 1.6,
						textAlign: 'justify',
						whiteSpace: 'pre-line',
						fontWeight: '800',
						fontFamily: 'Serif',
						color: 'yellow'
					}}>
					{'\t\t\t\t（四）共享、转移、披露信息时事先征得授权同意的例外\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t以下情形中，分享、转让、披露您的信息无需事先征得您的授权同意：\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t1、与国家安全、国防安全有关的；\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t2、与公共安全、公共卫生、重大公共利益有关的；\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t3、与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t5、您自行向社会公众公开的信息；\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t6、从合法公开披露的信息中收集信息的，如合法的新闻报道、政府信息公开等渠道。\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t根据法律规定，共享、转让、披露经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。\n'}
				</div>
				{
				'\t\t\t\t\n' +
				'\t\t\t\t三、我们如何存储您的个人信息\n' +
				'\t\t\t\t\n' }
				<div
					variant={'body2'}
					style={{
						lineHeight: 1.6,
						textAlign: 'justify',
						whiteSpace: 'pre-line',
						fontWeight: '800',
						fontFamily: 'Serif',
						color: 'yellow'
					}}>
					{'\t\t\t\t（一）存储期限\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t除法律、行政法规及其他规定外，我们仅在本政策所述目的所必需且最短的时限内存储您的个人信息。前述期限届满后，我们将对您的个人信息做删除或匿名化处理。\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t如我们终止服务或运营，我们将及时停止继续收集您个人信息的活动，同时会遵守相关法律法规要求提前向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理，但法律法规或监管部门另有规定的除外。\n'}
				</div>
				{
				'\t\t\t\t\n' +
				'\t\t\t\t（二）存储地点\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，以下情形除外：\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t1、获得您的授权同意；\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t2、法律法规有明确规定；\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t3、您使用的产品、服务涉及跨境，Youla平台需要向境外提供您的个人信息的。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t针对以上情形，我们会确保依据本政策及国家法律法规要求对您的个人信息提供足够的保护。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t四、我们如何保护您的个人信息\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（一）数据安全技术措施\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改，避免数据的损坏或丢失。目前，我们通过了国家互联网网络安全评估的测评和联网公安备案，确保您的个人信息安全性得到合理保障。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t我们的服务采取了多种加密技术，例如在某些产品中，我们将利用加密技术（例如SSL）来保护您的个人信息，采取加密技术对您的个人信息进行加密保存。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t我们还会采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（二）我们为保护个人信息采取的其他安全措施\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。\n' +
				'\t\t\t\t\n'}
				<div
					variant={'body2'}
					style={{
						lineHeight: 1.6,
						textAlign: 'justify',
						whiteSpace: 'pre-line',
						fontWeight: '800',
						fontFamily: 'Serif',
						color: 'yellow'
					}}>
					{'\t\t\t\t（三）我们仅允许有必要知晓这些信息的Youla员工、合作伙伴访问您的个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您的个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与Youla的合作关系。\n'}
				</div>
				{
				'\t\t\t\t\n' +
				'\t\t\t\t（四）我们会采取一切合理可行的措施，确保未收集无关的个人信息。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（五）互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的信息安全。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（六）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的个人信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致个人信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（七）安全事件处置\n' +
				'\t\t\t\t\n' }
				<div
					variant={'body2'}
					style={{
						lineHeight: 1.6,
						textAlign: 'justify',
						whiteSpace: 'pre-line',
						fontWeight: '800',
						fontFamily: 'Serif',
						color: 'yellow'
					}}>
					{'\t\t\t\t若不幸发生信息安全事件，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以网站公告、推送通知、弹窗提示、短信、电话或其他方式告知您，难以逐一告知信息主体时，我们会采取合理、有效的方式发布公告。\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您需要了解，您接入Youla服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t请您务必妥善保管好您的账号、密码及其他身份要素。您在使用Youla服务时，我们会通过您的账号、密码及及其他身份要素来识别您的身份。一旦您泄露了前述信息，您可能会蒙受损失，并可能对您产生不利。如您发现账号、密码及/或其他身份要素可能或已经泄露时，请您立即和我们取得联系，以便我们及时采取相应措施以避免或降低相关损失。\n'+
				'\t\t\t\t\n' +
				'\t\t\t\t五、如何行使您的个人信息相关权利\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（一）查阅、复制、更正、补充和删除\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t在您使用我们的服务期间，我们将根据具体情况为您提供管理您的个人信息的相应操作设置，以便您可以查阅、复制、转移、更正、补充、删除或撤回您的相关个人信息。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t1、查阅个人信息\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t查阅身份认证、头像、昵称、所在城市、年龄、星座、身高、职业、年薪、个人介绍、微信号等个人信息：\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t进入Youla，选择“我的”页面，查看“个人基本信息”\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t查看“小事”:\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t进入Youla，选择“小事”页面，查看“小事列表信息”\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t2、更正、补充个人信息\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t更正、补充身份认证、头像、昵称、所在城市、生日、身高、职业、年薪、个人介绍、微信号等：\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t1）进入Youla，选择“我的”页面\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t2）选择想要修改的信息模块，看箭头点击进入\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t3）修改后点击保存\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t3、删除或举报“小事”:\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t1）进入Youla，选择“小事”页面\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t2）点击“小事列表中cell右上角更多标记按钮”-“弹窗”-“删除/举报”，删除/举报“小事”\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t4、删除一对一聊天记录：\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t1）进入Youla，点“消息”\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t2）选择对话框，长按获得“删除提示”\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t3）选择“确定”\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t5、注销删除账户：\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t1）进入Youla，选择“我的”页面\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t2）点击左上角“设置”，点击“注销账户”\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t3）点击“确定注销”，即可注销账户\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t在您申请账号注销时，我们将在收到您的账号注销申请后7日内完成注销处理，如果您在7日内继续使用Youla账号并撤销申请，我们可恢复您的账号信息。否则，您的账号将被正式注销，即对您的个人信息进行删除或匿名化处理，使其保持不可被检索、访问的状态。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t请您理解，由于技术所限、法律或监管要求，我们可能无法满足您的所有要求，我们会在合理的期限内答复您的请求。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t除法律法规另有规定，当您更正、删除您的个人信息或申请注销账号时，我们可能不会立即从备份系统中更正或删除相应的信息，但会在人工审查时更正或删除这些信息。\n'}</div>
				{
				'\t\t\t\t\n' +
				'\t\t\t\t（二）公开与分享\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t我们的多项服务可让您不仅与您的社交网络、也与使用该服务的所有用户公开分享您的相关信息，例如，您在Youla服务中所上传或发布的信息、您对其他人上传或发布的信息作出的回应，通过在Youla服务中不特定用户可见的公开区域内上传或公布您的信息，以及包括与这些信息有关的位置数据和日志信息。只要您不删除您所公开或共享的信息，有关信息可能一直留存在公众领域；即使您删除共享信息，有关信息仍可能由其他用户或不受我们控制的第三方独立地缓存、复制或储存，或由其他用户或该等第三方在公众领域保存。如您将信息通过上述渠道公开或共享，由此造成您的信息泄露，我们不承担责任。因此，我们提醒并请您慎重考虑是否通过上述渠道公开或共享您的信息。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（三）注销\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t您可以通过【我的】-【设置】-【注销账号】注销账号或通过联系客服人工注销账号，我们将注销您的账号并删除有关个人信息，您可以在【我的】-【设置】-【联系我们】/【反馈信息】，进入用户反馈界面或者拨打客服电话：13922120454。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t在符合Youla单项服务的服务协议约定条件及国家相关法律法规规定的情况下，您的该项Youla服务账号可能被注销或删除。当账号注销或被删除后，与该账号相关的、该单项服务项下的全部服务资料和数据将依照单项服务的服务协议约定删除或处理，但法律法规另有规定的除外。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（四）撤回同意\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t您总是可以选择是否披露信息。有些信息是使用Youla服务所必需的，但大多数其他信息的提供是由您决定的。您可以通过删除信息、关闭设备功能的方式改变您授权我们继续收集信息的范围或撤回您的授权。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t当您撤回授权后，我们无法继续为您提供撤回授权所对应的服务，也不再处理您相应的信息。但您撤回授权的决定，不会影响此前基于您的授权而开展的信息处理。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t您可以通过【我的】-【设置】-【联系我们】页面，联系客服后，请求撤回同意隐私政策、用户协议并退出本平台。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（五）约束信息系统自动决策\n' +
				'\t\t\t\t在某些业务功能中，我们可能仅依据信息系统、算法在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害本平台商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（六）逝者近亲属的权利\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t如Youla平台用户不幸逝世，其近亲属可以通过拨打人工客服热线13922120454与我们联系，以依法行使其对逝者的相关个人信息行使法定权利。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（七）投诉举报\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t您可以按照我们公示的制度进行投诉或举报。如果您认为您的个人信息权利可能受到侵害，或者发现侵害个人信息权利的线索，您可以在【我的】-【设置】-【联系我们】，或进入用户反馈界面，或拨打客户服务热线：13922120454与我们联系。我们核查后会在15日内反馈您的投诉与举报。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（八）停止运营向您告知\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t如我们停止运营，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您的个人信息进行删除或匿名化处理。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（九）访问隐私政策\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t您可以在个人账号【我的】-【设置】- 【隐私政策】查看本隐私政策的全部内容。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t（十）响应您的上述请求\n' +
				'\t\t\t\t为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。我们将在15日内做出答复。如您对我们的答复不满意，还可以通过联系客服发起投诉。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。\n' +
				'\t\t\t\t\n'}
				<div
					variant={'body2'}
					style={{
						lineHeight: 1.6,
						textAlign: 'justify',
						whiteSpace: 'pre-line',
						fontWeight: '800',
						fontFamily: 'Serif',
						color: 'yellow'
					}}>
					{'\t\t\t\t六、我们如何处理未成年人的个人信息\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t我们的产品只面向成年人，不对未成年人提供服务，如果您未满十八周岁，请立即停止使用我们的产品。\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t我们根据国家相关法律法规的规定保护未成年人的个人信息，如果我们发现自己收集了未成年人的个人信息，会设法尽快删除相关数据。\n' +
					'\t\t\t\t\n'}
				</div>
				{
				'\t\t\t\t七、通知和修订\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t为了给您提供更好的服务，我们可能会根据产品或服务的更新情况及法律法规的相关要求适时修改本政策的条款，该等修改构成本政策的一部分。如该等更新造成您在本政策下权利的实质减少或重大变更，我们将在本政策生效前通过网站公告、推送通知、弹窗提示或其他方式来通知您，以便您及时了解本隐私政策的最新版本。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t我们的任何修改都会将您的满意度置于首位。我们鼓励您在每次使用Youla服务时都查阅我们的隐私政策。\n' +
				'\t\t\t\t\n' +
				'\t\t\t\t我们可能在必需时（例如当我们由于系统维护而暂停某一项服务时）发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于推广的公告。\n' +
				'\t\t\t\t\n' }
				<div
					variant={'body2'}
					style={{
						lineHeight: 1.6,
						textAlign: 'justify',
						whiteSpace: 'pre-line',
						fontWeight: '800',
						fontFamily: 'Serif',
						color: 'yellow'
					}}>
					{'\t\t\t\t八、如何联系我们\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t重庆月下童信息科技有限公司将按照本政策保护您的个人信息，如您有关于个人信息安全的投诉和举报，或您对本政策、您的信息的相关事宜有任何问题、意见或建议，请随时与我们联系。可发送邮件至contact@moonxt.cn。\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t如您对本政策或与本政策相关的事宜有任何问题，联系我们，我们将会为您做出解答。\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t一般情况下，我们将在收到您的问题、意见或建议，并验证您的用户身份后的15日内予以受理并处理。\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t注册地址：重庆市北碚区施家梁镇桂和二路5栋1号1-143\n' +
					'\t\t\t\t\n' +
					'\t\t\t\t更新时间：2024年10月29日\n' +
					'\t\t\t\t生效时间：2023年11月13日'}
				</div>
			</div>
		</Container>
	)
}
